// src/components/WorkExp.js

import React from 'react';
import './ProjExp.css'; 
import Project from './Proj';
const ProjExp = () => {
  return (
    <section id="projects">

    {/* py_g22 */}
    
    <Project
      name="Chill"
      language="Python (Flask && SQLite)"
      description="A tinder-like dating app designed for TV lovers, matching users based on their TV genre preferences as well as other factors like geographical location."
      link="https://github.com/Qiyiiii/py_g22"
      photos={[
        'imgs/chill1.png',
        'imgs/chill2.png',
        'imgs/chill3.png',
        'imgs/chill4.png'

      ]}
    />
    {/* Restify */}
    <Project
      name="Restify"
      language="JavaScript & Python (React Rest Framework && Django && SQLite)"
      description="AirBnb like website. Django backend and React frontend. Users can post the information of their residences or find available residences that satisfy their requirements, which includes location, time range, capacity, facilities, etc. After that, the users can also send request to the owner of the property to book the residence for certain time range. And the owner can handle the request in different ways and ignored request will be expired after 30 days. Lastly, if the request is approved, the listing will be removed from the website"
      link="https://github.com/Qiyiiii/Restify"
      photos={[
        'imgs/r1.png',
        'imgs/r2.png',
        'imgs/r4.png',
        'imgs/r5.png',
        'imgs/r6.png',

      ]}
    />


    {/* CSC413FinalProject */}
    <Project
      name="Experiment of Watermark Algorithm (Uchida) in ML Models"
      language="Python"
      description="Emprical Research on Uchida's watermark algorithm within Bert, CNN and LSTM models trained using Stock dataset and Spam/Non-Spam dataset. Afterwards, we tried fine-tuning and pruning attack on the models to test on the robustness of the watermark."
      link="https://github.com/EclipseIsDead/CSC413FinalProject"
      photos={[


      ]}
    />
 
    {/* Computer-Vision */}
    <Project
      name="Computer Vision Tools"
      language="Python"
      description="Multiple projects that includes document scanner tool that can let user unwarp images based on keypoints, Morphing tool utilized backward mapping algorithm by directly computing the source_coords based on the Beier-Neely algorithm's formulation, And image impainting tools using Patch Match"
      link="https://github.com/Qiyiiii/Computer-Vision"
      photos={[


      ]}
    />


    {/* learning-software-engineering.github.io */}
    <Project
    name="Introduction to automated testing "
    language="English"
    description="Contributed to the open source library about the knowledge how to convey different automated testing methods, e.g. Unit Testing, Integration Testing, Regression Testing..."
    link = "https://github.com/Qiyiiii/learning-software-engineering.github.io"
    photos={[


    ]}
    />


    {/* Linear-Regression-Model-of-Health-Expenditure */}
    <Project 
    name="Linear Regression Model of Health Expenditure "
    language="Python"
    description="MLR Approach to  find how potential medical expense will vary based on different predictors in linear regression model."
    link = "https://github.com/Qiyiiii/Linear-Regression-Model-of-Health-Expenditure"
    photos={[


    ]}/>

    <Project
    name = "ML models for predictions of students' correctness on a diagnostic questions" 
    language="Python"
    description="Applied KNN, Item Response Theory (IRT) model, and Neural Network to predict students' correctness on a diagnostic question. Afterwards, we investigated on the potential improvement of IRT model by adding two extra parameter to the formula and change it from single-parameter model to three-parameter model to improve model's perofrmance by increasing flexibility"
    link = "https://github.com/zixiumeng/csc311-project/tree/main"/>

    {/* 343-Final-database-design */}
    <Project 
    name = "Database Design for Concert Ticket Business"
    Language="SQL & Python"
    description="Designed and implemented a relational database schema for a concert ticketing system, ensuring minimal redundancy and efficient data storage. Developed SQL scripts for defining schema, inserting data, and executing complex queries to analyze ticket sales, venue accessibility, and user activity. Conducted functional dependency analysis and normalized the schema into BCNF, applying decomposition algorithms and the Chase Test to ensure lossless and dependency-preserving transformations."
    link="https://github.com/Qiyiiii/343-Final-database-design"/>


<Project
        name="Mass Spring System Animator"
        language="C++"
        description="By utilizing Physics, model the shape's physical behavior by treating it as a network of point masses and springs. Given initial conditions, the system will animate following the laws of physics forward in time."
        link="https://github.com/Qiyiiii/Mass-Spring-System"
        photos={['imgs/flag.gif','imgs/net.gif','imgs/skirt.gif']}
      />
      <Project
        name="Kinematics Animator"
        language="C++"
        description="animating shapes rigged to an internal skeleton by applying Inverse Kinematics to determine the final position of each part at each keyframe"
        link="https://github.com/Qiyiiii/Kinematics"
        photos={['imgs/robot-arm.gif','imgs/hand.gif']}
      />

      <Project
        name="Meshes"
        language="C++"
        description="Creating a mesh to represent a 3D object by mapping triangles (or polygons) to specific locations in 3D space. Applying a subdivision surface algorithm to recursively smooth vertices and improve the mesh's visual quality."
        link="https://github.com/Qiyiiii/Meshes"
        photos={['imgs/cube.gif', 'imgs/bob-sub.gif']}
      />

      <Project
        name="Ray-Tracing"
        language="C++"
        description="Produce renderings of scenes illuminated with light by recursively computing the lit color of a hit object in the scene using Blinn-Phong shading model"
        link="https://github.com/Qiyiiii/Ray-Tracing"
        photos={['imgs/s.gif']}
      />

      <Project
        name="Frogger"
        language="Assembly Language"
        description="Using MIP assembly language under MARS develop enviroment to create the famous Arcade game: Frogger"
        link="https://github.com/Qiyiiii/frogger"
        photos={['imgs/f.gif']}
      />
      <Project
        name="productive-app"
        language="Java (JAVAFX for GUI)"
        description="Productivity app which allows user to create account set tasks individually or within a group. Moreover, users can create and join the group, and the leader of the group can assign tasks to members. If the task is approaching or pass the deadline, the user will be reminded in the notification system"
        link="https://github.com/Qiyiiii/CSC207-productive-app"
        photos={[]}
      />
  </section>
  );
};

export default ProjExp;
