import React, { useState } from 'react';

const Project = ({ name, language, description, link, photos }) => {
  const [preview, setPreview] = useState(false); // Boolean to track preview toggle

  const handleLinkClick = (e) => {
    e.preventDefault(); // Prevent navigation
    setPreview(!preview); // Toggle preview state
  };

  return (
    <article className="project">
      <h3>{name}</h3>
      <p><span className='category'>Language:</span> {language}</p>
      <p><span className='category'>Description:</span> {description}</p>

      {photos && photos.length > 0 ? (
        <button
          onClick={handleLinkClick}
          className=' prev-button'
        >
          {preview ? 'Hide Preview' : 'Show Preview'}
        </button>
      ) : (
        <a href={link} target="_blank" rel="noopener noreferrer">
          Visit Repository
        </a>
      )}

      {/* Render preview only if there are photos */}
      {preview && photos && photos.length > 0 && (
        <div className="preview">
          <div className="photo-gallery" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            {photos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`Preview ${index + 1}`}
                className='proj-img'
       
              />
            ))}
          </div>
          <p>
            More:{" "}
            <a href={link} target="_blank" rel="noopener noreferrer">
              Visit Repository
            </a>
          </p>
        </div>
      )}
    </article>
  );
};

export default Project;

