// src/components/WorkExp.js

import React from 'react';
import './WorkExp.css'; 

const WorkExp = () => {
  return (
  <section id="work">
     <article class="work-experience">
     <h3><a href="https://www.manulife.ca/personal.html" className='comp-link'>Manulife</a>(ongoing)</h3>
    <p>
    As a Data Science Intern, I am currently working on the development of a multi-agent system by designing and implementing <strong>a verification agent</strong> that validates agent outputs within <strong>Multi Agent System</strong> using Langgraph. In the validation process, the agent, utilizing LLM, will determine wether the user input, after another agent transferred it into well-formatted queries, meets the requirements to be passed into next level.
    </p>
    <p>Hopefully, our validation agent can be modularized and provided as part of the langgraph package, enabling seamless integration into multi-agent systems for validation tasks.</p>
     </article>

  <article class="work-experience">
    <h3><a href="http://www.finedo.cn" className='comp-link'>Finedo Co.Ltd</a></h3>
    <p>During my time at <strong>Finedo</strong>, I worked on some pretty cool projects supervised by the data scientist team, including collaborating with China Mobile to deliver AI-powered solutions for <strong>sequencial classification</strong> tasks, e.g. classcify user inqueries into hierarchical categories of service. I helped utilize neural network and build BERT-based models to categorize user inquiries, handling a huge dataset with over 120,000 entries. </p>
      <p>We also implemented a system that <strong>automated receipt processing</strong> using computer vision framework (YOLO v3) together with an <strong>object detection system for fire prevention measures around eletric power facilities</strong>, making workflows much more efficient. Along the way, I learned a lot about model evaluation techniques like F1-Score, Recall, and MSE, which helped us improve the overall performance of our models.</p>
  </article>

  <article class="work-experience">
    <h3><a href='https://www.oise.utoronto.ca/home' className='comp-link'>Ontario Institute for Studies in Education (OISE)</a></h3>
    <p>
    At <strong>OISE</strong>, I worked as a <strong>Full-stack Developer Intern</strong> on an exciting project called <strong><a href='https://discovery.balanceai.ca/' className='proj-name'>BalanceAI Develop</a></strong> led by Dr. Eunice Jang.
  </p>
  <p>
    BalanceAI Develop is designed to help teachers and parents assess kindergarten-aged children in terms of literacy, recognition, and phonological awareness skills, in a way that is fun and engaging to children. Upon logging into an account, students are able to pick between a spelling task, a naming task, and a listening task, where they are able to complete assessments in the form of educational games.
  </p>
  <p>
    My responsiblity is to build and deploy student dashboard and functionalities on firebase. I helped build a secure user registration system, complete with frontend validation and password encryption, which was my first deep dive into full-stack development. I also created real-time reporting tools for student progress and worked on dynamic interfaces for various cognitive tasks for the <strong>Talk2Me Jr.</strong> project, allowing students to engage with interactive tasks like story recall and word recognition. 
  </p> 
  </article>
</section>

  );
};

export default WorkExp;
